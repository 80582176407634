<script setup>
    import { ref, defineProps } from 'vue'

    const menu = ref();
    const toggle = (event) => {
        menu.value.toggle(event);
    };

    const props = defineProps({
        id: Number,
        items: Array
    })
</script>

<template>
    <Button type="button" icon="pi pi-ellipsis-v" @click="toggle"  aria-haspopup="true" :aria-controls="'overlay_menu_'+props.id" class='ml-2 mb-2'/>
    <Menu ref="menu" :id="'overlay_menu_'+props.id" :model="items" :popup="true" />
</template>

