<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco Aziende</h4>
                </div>
                <DataTable :value='rows' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters' filterDisplay='menu' :loading='loading'
                           responsiveLayout='scroll'
                           >
                    <template #header>
                        <ListHeader
                            @clear='clearFilter()'
                            v-model="filters['global'].value"
                            @add='$router.push({ path: gotoNew() })'
                            @refresh='refreshList()'
                            :loading='loading'
                        />
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='name' header='Nome' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.name }}
                        </template>
                    </Column>

                    <Column field='slug' header='Slug' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.slug }}
                        </template>
                    </Column>

                    <Column field='id'>
                        <template #header>
                            Azioni <font-awesome-icon
                            class='text-purple-300 hover:text-purple-400 pointer ml-2'
                            title='Apri in nuovo Tab'
                            :icon="['fas', 'external-link-alt']"
                            size='1x'
                            v-if='linkInNewPage === false'
                            @click='linkInNewPage = true'
                        /><font-awesome-icon
                            class='text-blue-400 hover:text-blue-600 pointer ml-2'
                            title='Apri nello stesso Tab'
                            :icon="['fas', 'file-import']"
                            size='1x'
                            v-else
                            @click='linkInNewPage = false'
                        /></template>
                        <template #body='slotProps'>
                            <router-link :to='gotoId(slotProps.data.id)' :target='linkInNewPage ? "_blank" : "_self"'>
                                <Button icon='pi pi-pencil'
                                        class='mr-2 mb-2'
                                        :class='{"p-button-info": !linkInNewPage, "p-button-help": linkInNewPage}'
                                        title='Modifica'></Button>
                            </router-link>

                            <router-link :to="this.$route['meta']['basePath'] + '/' + slotProps.data.id + '/company_teams'" :target='linkInNewPage ? "_blank" : "_self"'>
                                <font-awesome-icon
                                    class='text-indigo-600 hover:text-purple-400 pointer ml-1'
                                    :style='{marginBottom: "5px", fontSize: "36px"}'
                                    title='Teams'
                                    :icon="['fas', 'sitemap']"
                                    size='2x'
                                />
                            </router-link>
                            <router-link :to="this.$route['meta']['basePath'] + '/' + slotProps.data.id + '/paths'" :target='linkInNewPage ? "_blank" : "_self"'>
                                <font-awesome-icon
                                    class='text-orange-500 hover:text-pink-500 pointer ml-2'
                                    :style='{marginBottom: "5px", fontSize: "36px"}'
                                    title='Percorsi'
                                    :icon="['fas', 'route']"
                                    size='2x'
                                />
                            </router-link>
                            <router-link :to="this.$route['meta']['basePath'] + '/' + slotProps.data.id + '/users'" :target='linkInNewPage ? "_blank" : "_self"'>
                                <font-awesome-icon
                                    class='text-green-500 hover:text-green-700 pointer ml-2'
                                    :style='{marginBottom: "5px", fontSize: "36px"}'
                                    title='Utenti'
                                    :icon="['fas', 'user-friends']"
                                    size='2x'
                                />
                            </router-link>
                            <router-link :to="this.$route['meta']['basePath'] + '/' + slotProps.data.id + '/dashboard_b2b'" :target='linkInNewPage ? "_blank" : "_self"'>
                                <font-awesome-icon
                                    class='text-purple-400 hover:text-purple-700 pointer ml-2'
                                    :style='{marginBottom: "5px", fontSize: "36px"}'
                                    title='Accesso Dashboard'
                                    :icon="['fas', 'solar-panel']"
                                    size='2x'
                                />
                            </router-link>
                            <DD :id='slotProps.data.id' :items='companyMenuItem(slotProps.data.id)'></DD>
                        </template>
                    </Column>

                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import DD from './DD.vue';
const { mapActions, mapGetters } = createNamespacedHelpers('Users/Companies');
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';

import ListHeader from '../../../components/4books/ListHeader';

export default {
    components: {
        ListHeader, DD
    },
    data() {
        return {
            filters: null,
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        ...mapActions(['fetchList', 'downloadPinnedSkillsReport']),
        refreshList() {
            this.fetchList().then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        companyMenuItem(id){
            return [
                {
                    label: 'Scarica Report su Skills XPs',
                    icon: 'pi pi-fw pi-download',
                    command: () => {
                        this.downloadSRep(id);
                    }
                }
            ];
        },
        downloadSRep(id){
            this.downloadPinnedSkillsReport(id).then(res => {
                this.downloadFile('report.csv', res);
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        }
    },
    mixins: [
        Notifications,
        Navigations,
    ],
};
</script>
